export const getMetaFieldProperties = (id, value) => {
    return {
            isLocal: true,
            value,
            key: id,
            namespace: "SellerApp",
            permission_set: "read_and_sf_access",
    }
}

export const getCustomFieldProperties = ({id, name, value}) => {
    return ({...(!!id && {id}), name, value});
}

export const getDiscountedPriceValue = ({ sale_price, price, brandId }) => {
    if(sale_price > 0) {
        return "Yes";
    }

    const priceStr = price.toString();
    switch(brandId) {        
        case "1": {
            return ["91", "83"].some(ending => priceStr.endsWith(ending)) ? 'Yes' : 'No';
        }
        case "4": {
            return ["96", "97", "98", "00"].some(ending => priceStr.endsWith(ending)) ? 'Yes' : 'No';
        }
    }

    return "No";
}