import React, { FC } from 'react';
// import { Provider } from 'react-redux';
// import store from '../redux/store';

// @ts-ignore
const GlobalStateProvider: FC = ({ children }) => {
  return (
    <></>
    // <Provider>
    //     {children}
    // </Provider>
  );
};

export default GlobalStateProvider;
