import { parse, addDays, isAfter } from 'date-fns';

const showPublishButton = ({isImageAdded, inventory_level}) => {
    if(isImageAdded && inventory_level > 0) {
        return true
    }
};

const getPublishAttributes = (dateCreatedStr, publishDelay, condition) => {
    if(condition == "Used") {
        const date = dateCreatedStr?.split(" ");
        const dateCreated = parse(date?.[0], 'M/d/yyyy', new Date());
        const publishDate = addDays(dateCreated, publishDelay);
        const now = new Date();

        if (isAfter(now, publishDate)) {
            return { is_featured: true, is_visible: true };
        } else {
            return { is_featured: true };
        }
    } else {
        return { is_featured: true, is_visible: true };
    }
}

export { showPublishButton,  getPublishAttributes };
